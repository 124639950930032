<template>
  <v-container
    class="pa-2"
    style="overflow-y: auto; height: 100%; width: 100%"
    fluid
  >
    <v-btn
      v-if="$vuetify.breakpoint.mdAndUp"
      left
      absolute
      elevation="0"
      icon
      color="neutral"
      @click="goBack"
    >
      <v-icon>{{ icons.chevronLeft }}</v-icon>
    </v-btn>

    <v-form
      ref="form"
      v-model="valid"
      :class="$vuetify.breakpoint.md ? 'mt-8': 'mt-0'"
    >
      <v-container
        fluid
        style="max-width: 1200px"
      >
        <v-col dense>
          <div class="d-flex align-center mb-2">
            <v-icon
              class="mr-2"
              color="textBlack"
              :size="$vuetify.breakpoint.mobile? 24 : 28"
            >
              {{ icons.folder }}
            </v-icon>
            <div class="subtitle-1 font-weight-bold textBlack--text">
              {{ $t('deconve.updateOccurrenceDetails') }}
            </div>
          </div>
          <div class="d-flex justify-space-between align-center mt-6">
            <span class="font-weight-medium caption text-sm-body-1">
              {{ $t('deconve.name') }}
            </span>
          </div>
          <div class="d-flex align-center mt-2">
            <v-text-field
              v-model="occurrenceName"
              data-cy="person-field-name"
              :counter="64"
              :rules="nameRules"
              class="body-2 text-sm-body-1"
              required
              outlined
            />
          </div>

          <div class="d-flex align-center mt-2">
            <span class="font-weight-medium caption text-sm-body-1">
              {{ $t('deconve.occurrenceStatus') }}
            </span>
          </div>
          <div class="d-flex align-center mt-2">
            <input-options
              v-model="occurrenceStatus"
              min-width="100px"
              :options="statusValues"
            />
          </div>
          <div class="d-flex align-center mt-6">
            <span class="font-weight-medium caption text-sm-body-1">
              {{ $t('deconve.occurrenceValueOfPrevention') }}
            </span>
          </div>
          <div class="d-flex align-center mt-2">
            <v-text-field
              v-model="occurrenceValueOfPrevention"
              type="number"
              :rules="preventionAndLossRules"
              max-width="100%"
              :prefix="currencyPrefix"
              dense
              outlined
              required
            />
          </div>
          <div class="d-flex align-center">
            <span class="font-weight-medium caption text-sm-body-1">
              {{ $t('deconve.occurrenceValueOfLoss') }}
            </span>
          </div>
          <div class="d-flex align-center mt-2">
            <v-text-field
              v-model="occurrenceValueOfLoss"
              type="number"
              :rules="preventionAndLossRules"
              max-width="100%"
              :prefix="currencyPrefix"
              dense
              outlined
              required
            />
          </div>
          <div class="d-flex flex-wrap mt-1">
            <v-checkbox
              v-model="occurrenceCopsWereCalled"
              class="pa-0 ma-0"
              :label="$t('deconve.policeWereCalled')"
            />
          </div>
          <div class="d-flex flex-wrap mt-1">
            <v-checkbox
              v-model="occurrenceArrest"
              class="pa-0 ma-0"
              :label="$t('deconve.thereWereAnArrest')"
            />
          </div>

          <div class="d-flex justify-space-between align-center mt-2">
            <span class="font-weight-medium caption text-sm-body-1">
              {{ $t('deconve.tags') }}
            </span>
            <v-btn
              outlined
              color="neutral"
              :small="$vuetify.breakpoint.mobile"
              @click="openTagModal"
            >
              <v-icon :left="!$vuetify.breakpoint.mobile">
                {{ icons.tagPlus }}
              </v-icon>

              <div v-if="!$vuetify.breakpoint.mobile">
                {{ $t('deconve.tag.addTags') }}
              </div>
            </v-btn>
          </div>

          <div class="d-flex justify-space-between align-center mt-4">
            <v-col class="pa-0">
              <div
                v-if="occurrenceTags.length > 0"
                class="d-flex flex-wrap"
              >
                <tag
                  v-for="tag in occurrenceTags"
                  :key="tag.id"
                  class="pa-1"
                  :tag-id="tag.id"
                />
              </div>
            </v-col>
          </div>

          <div class="d-flex justify-space-between align-center mt-4">
            <span class="font-weight-medium caption text-sm-body-1">
              {{ $t('deconve.occurrenceFiles') }}
            </span>
            <v-btn
              outlined
              color="neutral"
              :small="$vuetify.breakpoint.mobile"
              @click="openFileSelection('file-input')"
            >
              <v-icon :left="!$vuetify.breakpoint.mobile">
                {{ icons.fileDocumentPlus }}
              </v-icon>

              <div v-if="!$vuetify.breakpoint.mobile">
                {{ $t('deconve.addOccurrenceFiles') }}
              </div>
            </v-btn>
            <input
              id="file-input"
              type="file"
              multiple
              required
              style="display: none;"
              @change="loadFiles($event.target.files)"
            >
          </div>

          <div class="d-flex justify-space-between align-center mt-4">
            <v-col class="pa-0">
              <div
                v-if="files.length > 0"
                class="d-flex flex-wrap"
              >
                <file-item
                  v-for="file in files"
                  :key="file.name"
                  is-edit-mode
                  :name="file.name"
                  class="pa-1"
                  @delete="handleDeleteFile(file.name)"
                />
              </div>
            </v-col>
          </div>

          <div class="d-flex align-center mt-6">
            <span class="font-weight-medium caption text-sm-body-1 mb-n3">
              {{ $t('deconve.about') }}
            </span>
          </div>
          <div class="d-flex align-center mt-5">
            <v-textarea
              v-model="occurrenceAbout"
              :counter="maxAboutTextLength"
              :rules="aboutRules"
              class="body-2 text-sm-body-1"
              height="100"
              auto-grow
              outlined
            />
          </div>

          <div class="d-flex justify-space-between align-center mt-6">
            <span class="font-weight-medium caption text-sm-body-1">
              {{ $t('deconve.occurrenceNotifications') }}
            </span>
            <v-btn
              outlined
              color="neutral"
              :small="$vuetify.breakpoint.mobile"
              @click="openNotificationSelectionDialog"
            >
              <v-icon :left="!$vuetify.breakpoint.mobile">
                {{ icons.storePlus }}
              </v-icon>

              <div v-if="!$vuetify.breakpoint.mobile">
                {{ $t('deconve.addOccurrenceNotifications') }}
              </div>
            </v-btn>
          </div>
          <div class="d-flex flex-wrap align-center mt-5">
            <v-col
              v-for="notification in notificationsPreview"
              :key="notification.id"
              class="pa-1"
              style="height: fit-content"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <notification-preview
                :id="notification.id"
                :key="notification.id"
                :data="notification"
              />
            </v-col>
          </div>

          <div class="d-flex flex-wrap mt-6">
            <v-checkbox
              v-model="isOccurrenceFinished"
              class="pa-0 ma-0"
              :label="$t('deconve.finishOccurrence')"
            />
          </div>

          <v-col class="mt-4">
            <v-row class="d-flex justify-end">
              <v-btn
                color="warn"
                outlined
                :height="$vuetify.breakpoint.mobile? '36px' : '40px'"
                elevation="0"
                class="d-flex custom-transform-class text-none px-3"
                :x-small="$vuetify.breakpoint.mobile"
                @click="cancel"
              >
                {{ $t('deconve.cancel') }}
              </v-btn>
              <v-btn
                color="primary"
                :height="$vuetify.breakpoint.mobile? '36px' : '40px'"
                elevation="0"
                :loading="isUpdatingOccurrence"
                class="d-flex custom-transform-class text-none px-3 ml-2"
                :x-small="$vuetify.breakpoint.mobile"
                @click="confirm"
              >
                {{ $t('deconve.submit') }}
              </v-btn>
            </v-row>
          </v-col>
        </v-col>
      </v-container>
    </v-form>
    <tags-manager-dialog ref="tagDialog" />
    <notification-selection-dialog ref="notificationSelectionDialog" />
    <confirmation-dialog ref="confirm" />
    <div v-if="alert && alertMessageText">
      <v-snackbar
        v-model="alert"
        data-cy="snackbar"
        :color="getAlertColor"
      >
        {{ alertMessageText }}

        <template v-slot:action="{ attrs }">
          <v-btn
            text
            v-bind="attrs"
            @click="resetAlert"
          >
            <v-icon color="white">
              {{ icons.close }}
            </v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-container>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';
import {
  mdiFileDocumentPlusOutline,
  mdiVideoBox, mdiStorePlusOutline, mdiClose,
  mdiReload, mdiTagPlusOutline, mdiChevronLeft,
  mdiFolderEditOutline,
} from '@mdi/js';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import NotificationSelectionDialog from '@/components/NotificationSelectionDialog.vue';
import NotificationPreview from '@/components/NotificationPreview.vue';
import InputOptions from '@/components/InputOptions.vue';
import { OCCURRENCE_THEFT_STATUS } from '@/utils/occurrence';
import Tag from '@/components/Tag.vue';
import TagsManagerDialog from '@/components/TagsManagerDialog.vue';
import FileItem from '@/components/FileItem.vue';

export default {
  name: 'OccurrenceUpdate',
  components: {
    ConfirmationDialog,
    NotificationSelectionDialog,
    NotificationPreview,
    InputOptions,
    Tag,
    FileItem,
    TagsManagerDialog,
  },
  props: {
    occurrenceId: { type: String, default: '' },
  },
  data: () => ({
    valid: true,
    alert: false,
    alertMessage: '',
    alertColor: '',
    occurrenceAbout: '',
    isUpdatingOccurrence: false,
    occurrenceCopsWereCalled: false,
    occurrenceArrest: false,
    occurrenceValueOfPrevention: 0,
    occurrenceValueOfLoss: 0,
    occurrenceStatus: 0,
    occurrenceName: '',
    maxAboutTextLength: 600,
    notificationsPreview: [],
    occurrenceFiles: [],
    notificationsToAddInOccurrence: [],
    files: [],
    occurrence: null,
    occurrenceFilesToSubmit: [],
    occurrenceFilesToBeDeleted: [],
    occurrenceTags: [],
    isOccurrenceFinished: false,
    isLoading: false,
    icons: {
      storePlus: mdiStorePlusOutline,
      tagPlus: mdiTagPlusOutline,
      fileDocumentPlus: mdiFileDocumentPlusOutline,
      folder: mdiFolderEditOutline,
      video: mdiVideoBox,
      reload: mdiReload,
      chevronLeft: mdiChevronLeft,
      close: mdiClose,
    },
  }),
  computed: {
    ...mapGetters({
      getOccurrence: 'faceid/getOccurrence',
    }),
    alertMessageText() {
      return this.alertMessage;
    },
    getAlertColor() {
      return this.alertColor;
    },
    currencyPrefix() {
      const { locale } = this.$i18n;

      let sufix = '';

      if (locale === 'pt-br') sufix = 'R$';
      if (locale === 'en-us') sufix = '$';

      return sufix;
    },
    statusOptions() {
      return {
        stole: this.$t('deconve.stole'),
        triedToSteal: this.$t('deconve.triedToStealButWasStopped'),
        withoutAlteration: this.$t('deconve.withoutAlteration'),
      };
    },
    statusValues() {
      return [
        this.statusOptions.stole,
        this.statusOptions.triedToSteal,
        this.statusOptions.withoutAlteration,
      ];
    },
    occurrenceStatusValues() {
      return [
        OCCURRENCE_THEFT_STATUS.stole,
        OCCURRENCE_THEFT_STATUS.triedToSteal,
        OCCURRENCE_THEFT_STATUS.didNotTryToSteal,
      ];
    },
    aboutRules() {
      return [
        (v) => (!v || (v && v.length <= this.maxAboutTextLength))
          || this.$t('deconve.inputRules.about', [this.maxAboutTextLength]),
      ];
    },
    nameRules() {
      return [
        (v) => !!v || this.$t('deconve.inputRules.name.required'),
        (v) => (v && v.length <= 64) || this.$t('deconve.inputRules.name.limit'),
      ];
    },
    preventionAndLossRules() {
      return [
        (v) => parseFloat(v) >= 0 || this.$t('deconve.invalidValue'),
      ];
    },
    areRequiredFieldsMissing() {
      return this.occurrenceName === '' || this.occurrenceStatus === null
        || this.occurrenceAbout === '' || this.occurrenceValueOfPrevention === null || this.occurrenceValueOfLoss === null;
    },
    occurrenceUnitId() {
      return this.occurrence?.unit?.id;
    },
    occurrenceCreatedAt() {
      return this.occurrence?.created_at;
    },
  },
  created() {
    this.isLoading = true;
    this.resetAllOccurrenceFiles();

    if (this.occurrenceId) {
      this.fetchOccurrence(this.occurrenceId).then((data) => {
        this.occurrence = data;
        this.getOccurrenceData(data);
      });
    }
  },
  methods: {
    ...mapActions({
      fetchOccurrence: 'faceid/fetchOccurrence',
      patchOccurrence: 'faceid/patchOccurrence',
      addOccurrenceFiles: 'faceid/addOccurrenceFiles',
      deleteOccurrenceFiles: 'faceid/deleteOccurrenceFiles',
      fetchNotification: 'faceid/fetchNotification',
    }),
    resetAlert() {
      this.alert = false;
      this.alertColor = '';
      this.alertMessage = '';
    },
    showAlert(color, message) {
      this.alertColor = color;
      this.alertMessage = message;
      this.alert = true;
      this.isUpdatingOccurrence = false;
    },
    openFileSelection(inputId) {
      document.getElementById(inputId).click();
    },
    resetAllOccurrenceFiles() {
      this.files.length = 0;
      this.occurrenceFiles.length = 0;
      this.occurrenceFilesToSubmit.length = 0;
    },
    goBack() {
      this.$emit('onGoBackButtonClicked');
    },
    reset() {
      this.$refs.form.reset();
      this.resetAllOccurrenceFiles();
      this.resetAlert();
    },
    getOccurrenceData(occurrenceData) {
      this.occurrenceTags = [];
      this.occurrenceFiles = [];
      this.notificationsPreview = [];
      this.files = [];

      const {
        about,
        theft_status: occurrenceStatus,
        name: occurrenceName,
        cops_were_called: occurrenceCopsWereCalled,
        there_was_an_arrest: occurrenceArrest,
        value_of_loss: occurrenceValueOfLoss,
        value_of_prevention: occurrenceValueOfPrevention,
        tags: occurrenceTags,
        files: occurrenceFiles,
        notifications: occurrenceNotifications,
        is_finished: isOccurrenceFinished,
      } = occurrenceData;

      if (occurrenceStatus) {
        this.occurrenceStatus = this.occurrenceStatusValues
          .indexOf(occurrenceStatus);
      } else {
        this.occurrenceStatus = this.occurrenceStatusValues
          .indexOf(OCCURRENCE_THEFT_STATUS.didNotTryToSteal);
      }

      if (occurrenceName) {
        this.occurrenceName = occurrenceName;
      }

      this.occurrenceAbout = about;
      this.occurrenceCopsWereCalled = occurrenceCopsWereCalled;
      this.occurrenceArrest = occurrenceArrest;
      this.occurrenceValueOfLoss = occurrenceValueOfLoss;
      this.occurrenceValueOfPrevention = occurrenceValueOfPrevention;
      this.isOccurrenceFinished = isOccurrenceFinished;

      if (occurrenceFiles) {
        occurrenceFiles.forEach(
          ({ name }) => {
            this.files.push({ name });
            this.occurrenceFiles.push({ name });
          },
        );
      }

      if (occurrenceNotifications) {
        occurrenceNotifications.forEach(({ id: notificationId }) => {
          this.fetchNotification(notificationId).then((notificationData) => {
            const { person, video } = notificationData;

            if (!this.occurrenceName && video?.name) {
              this.occurrenceName = video.name;
            }

            this.notificationsPreview.push({
              id: notificationId,
              video: { id: video.id },
              person: { id: person.id },
            });
          });
        });
      }

      if (occurrenceTags) {
        occurrenceTags.forEach((tagId) => this.occurrenceTags.push({ id: tagId }));
      }
    },
    openTagModal() {
      this.$refs.tagDialog.open(this.occurrenceTags, true).then((response) => {
        if (response) {
          this.occurrenceTags = response;
        }
      });
    },
    openNotificationSelectionDialog() {
      this.$refs.notificationSelectionDialog.open(
        this.occurrenceUnitId, this.occurrenceCreatedAt, this.notificationsPreview,
      ).then((selectedNotifications) => {
        if (selectedNotifications?.length > 0) {
          this.notificationsPreview = [...selectedNotifications];
        }
      }).catch(() => {
        // nothing todo
      });
    },
    isFileSizeAccepted(files, maxFileSizeAllowedInBytes) {
      const index = files.findIndex((file) => file.size > maxFileSizeAllowedInBytes);

      return index < 0;
    },
    isFileNameSizeAccepted(files, maxFileNameSize) {
      const index = files.findIndex((file) => file.name.length > maxFileNameSize);

      return index < 0;
    },
    loadFilesHelper(files, currentFiles) {
      const maxFileNameSize = 128;
      const maxFileSizeAllowedInBytes = 20971520; // 20 MB
      const maxNumberOfFilesAllowed = 5;

      const filesArray = Array.from(files);

      if (!this.isFileSizeAccepted(filesArray, maxFileSizeAllowedInBytes)) {
        this.showAlert('warn', this.$t('deconve.occurrenceFileSizeTooBig'));
        return;
      }

      if (!this.isFileNameSizeAccepted(filesArray, maxFileNameSize)) {
        this.showAlert('warn', this.$t('deconve.person.alerts.fileNameTooLong'));
        return;
      }

      const numberOfFiles = currentFiles.length + filesArray.length;

      if (numberOfFiles > maxNumberOfFilesAllowed) {
        this.showAlert(
          'warn', this.$t('deconve.person.alerts.filesNumberLimit', [maxNumberOfFilesAllowed]),
        );
        return;
      }

      const filesAlreadyAdded = [];

      filesArray.forEach((file) => {
        const index = currentFiles.findIndex(({ name }) => name === file.name);
        const isANewFile = index < 0;

        if (isANewFile) {
          currentFiles.push({ name: file.name, file: URL.createObjectURL(file) });
        } else {
          filesAlreadyAdded.push(file.name);
        }
      });

      if (filesAlreadyAdded.length > 0) {
        this.showAlert(
          'info',
          this.$t('deconve.person.alerts.fileAlreadyAdded', [filesAlreadyAdded.join(', ')]),
        );
      }
    },
    loadFiles(files) {
      this.loadFilesHelper(files, this.files);
    },
    handleDeleteFile(fileName) {
      const index = this.occurrenceFiles.findIndex(
        (occurrenceFile) => occurrenceFile.name === fileName,
      );

      const isFileSaved = index >= 0;

      if (isFileSaved) {
        this.$refs.confirm.open(
          this.$t('deconve.removeFile'),
          this.$t('deconve.removeReportFile', [fileName]),
        ).then((confirm) => {
          if (confirm) {
            this.occurrenceFilesToBeDeleted.push(fileName);
            this.removeFileFromCurrentList(fileName, this.files);
          }
        });
      } else {
        this.removeFileFromCurrentList(fileName, this.files);
      }
    },
    removeFileFromCurrentList(fileName, fileList) {
      const index = fileList.findIndex((item) => item.name === fileName);
      const item = fileList[index];

      URL.revokeObjectURL(item.file);

      fileList.splice(index, 1);
    },
    prepareFilesToSubmit() {
      return new Promise((resolve) => {
        this.occurrenceFilesToSubmit = [];

        this.files.forEach(({ name, file }) => {
          if (file) {
            this.occurrenceFilesToSubmit.push({ name, file });
          }
        });

        resolve();
      });
    },
    submitFiles() {
      return new Promise((resolve) => {
        this.prepareFilesToSubmit().then(() => {
          if (this.occurrenceFilesToSubmit.length > 0) {
            resolve(this.addOccurrenceFiles({
              occurrenceId: this.occurrenceId,
              files: this.occurrenceFilesToSubmit,
            }));
          } else {
            resolve();
          }
        });
      });
    },
    confirm() {
      this.isLoading = true;
      this.isUpdatingOccurrence = true;

      const updateOccurrenceParams = new URLSearchParams();

      updateOccurrenceParams.append('name', this.occurrenceName);
      updateOccurrenceParams.append('theft_status', this.occurrenceStatusValues[this.occurrenceStatus]);
      updateOccurrenceParams.append('about', this.occurrenceAbout);
      updateOccurrenceParams.append('there_was_an_arrest', this.occurrenceArrest);
      updateOccurrenceParams.append('value_of_loss', this.occurrenceValueOfLoss);
      updateOccurrenceParams.append('value_of_prevention', this.occurrenceValueOfPrevention);
      updateOccurrenceParams.append('cops_were_called', this.occurrenceCopsWereCalled);
      updateOccurrenceParams.append('is_finished', this.isOccurrenceFinished);

      if (this.occurrenceTags.length > 0) {
        this.occurrenceTags.forEach((tag) => updateOccurrenceParams.append('tag_ids', tag.id));
      } else {
        updateOccurrenceParams.append('tag_ids', this.occurrenceTags);
      }

      if (this.notificationsPreview.length > 0) {
        this.notificationsToAddInOccurrence = this.notificationsPreview.map(({ id }) => ({ id }));
      }

      const promises = [this.submitFiles()];

      if (this.occurrenceFilesToBeDeleted.length > 0) {
        promises.push(this.deleteOccurrenceFiles({
          occurrenceId: this.occurrenceId,
          fileNames: this.occurrenceFilesToBeDeleted,
        }));
      }

      Promise.all(promises).then(() => {
        this.patchOccurrence({
          occurrenceId:
              this.occurrenceId,
          updateOccurrenceParams,
          notificationsToAdd: this.notificationsToAddInOccurrence,
        }).then((occurrence) => {
          this.occurrence = occurrence;
          this.$emit('edited');
          this.isLoading = false;
          this.isUpdatingOccurrence = false;
        });
      });
    },
    cancel() {
      this.$emit('canceled');
    },
  },
};
</script>

<style scoped>
textarea:focus {
  outline: 0;
}

.disableDiv {
  pointer-events: none !important;
}
</style>
